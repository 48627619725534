import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../../layouts/loader/Loader";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/store";

const SurveyComplete = () => {
  //used for navigation
  const navigate = useNavigate();
  const { loadingStatus, errorMessage } = useAppSelector((state) => state.assessmentformSlice);

  //translation
  const { t } = useTranslation();
  // console.log({ loadingStatus });
  return (
    <div className="surveyTest">
      {loadingStatus === "Success" && (
        <div className="TestInner">
          <Typography className="testTitle">
            {/* Thank you for completing the form. */}
            {t("main.completeSurvey.thankyou")}
          </Typography>
          <div onClick={() => navigate(-1)}>
            {/* Return to Home */}
            {t("main.completeSurvey.return_home")}
          </div>
        </div>
      )}

      {loadingStatus === "Loading" && (
        <div className="TestInner">
          <Typography className="testTitle">
            {/* We are loading your form, please wait for completion... */}
            {t("main.completeSurvey.loading_form")}
          </Typography>
          <Loader isShow={true} />
        </div>
      )}

      {loadingStatus === "Failed" && (
        <div className="TestInner">
          {errorMessage?.length > 0 ? (
            <Typography className="testTitle">
              {t("main.completeSurvey.loading_form_failed")} {errorMessage}{" "}
              {t("main.completeSurvey.try_again")}
            </Typography>
          ) : (
            <Typography className="testTitle">
              {/* Loading Form Failed. Please try it again. */}
              {t("main.completeSurvey.loading_form_failed")} {""}{" "}
              {t("main.completeSurvey.try_again")}
            </Typography>
          )}
        </div>
      )}
      {/* <div className="TestInner">
        <Typography className="testTitle">
          Thank you for completing the form.
        </Typography>
        <div onClick={() => navigate(-1)}>Return to Home</div>
      </div> */}
    </div>
  );
};

export default SurveyComplete;
