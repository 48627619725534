import {
  Agenda,
  Day,
  DragAndDrop,
  EventRenderedArgs,
  Inject,
  Month,
  PopupOpenEventArgs,
  RenderCellEventArgs,
  Resize,
  ResourceDetails,
  ResourceDirective,
  ResourcesDirective,
  Schedule,
  ScheduleComponent,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
  Week,
  Year,
} from "@syncfusion/ej2-react-schedule";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Internationalization, createElement } from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { IBookingData, IClientDetails } from "../../type/client";
import moment from "moment";
import { availabilityOverlap } from "../../utils/AvailabilityUtils";
import { ParentScheduleSetting, ServiceColor } from "../../type/schedule";
import { filterSchedulesByIds } from "../../utils/ScheduleUtils";
import { convertTime12to24 } from "../../constants/schedule";
import ClientImg from "../../assets/images/Image-svg/client-large.svg";
import "./planningCalendar.scss";
import DiscoverBookingEditModal from "./DiscoverBookingEditModal";
import { useTranslation } from "react-i18next";

interface IScheduleCalender {
  childIds: string[];
  childDetailList: IClientDetails[];
  scheduleSetting: ParentScheduleSetting;
}

export enum ParentScheduleAction {
  Cancel = "Cancel Booking",
  ReSchedule = "Re-Schedule",
}

const ScheduleCalendar: React.FC<IScheduleCalender> = ({
  childIds,
  childDetailList,
  scheduleSetting,
}) => {
  //#region variable region

  //translation
  const { t } = useTranslation();
  //stores client booking data
  const clientbooking = childDetailList?.flatMap((item) => item.bookingData);
  //stores list to show in Calendar
  const [listforCalendar, setlistforCalendar] = useState<any[]>([]);

  //stores event data
  const [eventData, setEventData] = useState<any[]>([]);

  //delete popup handler
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);

  //argdata store
  const [argsdata, setargsData] = useState<Record<string, any>>();

  //new and edit popup handler
  const [popup, setPopup] = useState<boolean>(false);

  //decide new and edit true value
  const [isEditPopup, setIsEditPopUp] = useState<boolean>(false);

  //decide cancel or re-booking flow
  const [scheduleAction, setScheduleAction] = useState<ParentScheduleAction>(
    ParentScheduleAction.Cancel
  );

  //used for access features
  let schedule: any = new Schedule({
    height: "600px",
    width: "100%",
  });

  let scheduleObj = useRef<ScheduleComponent>(schedule);

  //#endregion

  //#region methods region

  //for date Header
  let instance = useMemo(() => {
    return new Internationalization();
  }, []);

  //handles header text in Scheduler
  const getDateHeaderText = useCallback(
    (value: any) => {
      return instance.formatDate(value, { skeleton: "Ed" });
    },
    [instance]
  );

  //handles header template in Scheduler
  const dateHeaderTemplate = (props: any) => {
    return (
      <div>
        <div>{getDateHeaderText(props.date)}</div>
      </div>
    );
  };

  //handles resource name
  function getResourceName(value: ResourceDetails) {
    return (value as ResourceDetails).resourceData[
      (value as any).resource.textField
    ] as string;
  }

  //Customize calendar resource headers
  function resourceHeaderTemplate(props: any): JSX.Element {
    return (
      <div className="template-wrap">
        <div
          className="person-name meetingPersonBox"
          style={{
            background: getColor(props),
          }}
        >
          <img src={getResourceImage(props)} height={30} width={30} alt="" />
          <span>{getResourceName(props)}</span>
        </div>
      </div>
    );
  }

  //handle profile img url
  function getResourceImage(value: ResourceDetails) {
    if (value.resourceData.type === "Client") {
      return value.resourceData?.url || ClientImg;
    }
  }

  //handle background color for resource cell
  function getColor(value: ResourceDetails): string {
    if (value.resourceData.type === "Client") {
      return "#dafbeb";
    } else {
      return "";
    }
  }

  //populates list for calendar
  const handlePopulateResources = (
    selectedClients: any[],
    fromMatchResults: boolean = false
  ) => {
    let newList: any[] = [];

    let obj: any;

    //Add Client Into Calendar List
    selectedClients.forEach((selectedClient: any) => {
      obj = {
        name: `${selectedClient?.clientBasicDetails?.childFirstName}${" "}${
          selectedClient?.clientBasicDetails?.childLastName
        }`,
        id: selectedClient?.clientBasicDetails?.id,
        GroupId: 1, //selected provider category
        color: "#865fcf",
        url: selectedClient?.clientBasicDetails?.clientProfile?.url,
        type: "Client",
        hasAuth: selectedClient?.authorizations?.length > 0 ? true : false,
        hasAvailability:
          selectedClient?.availabilityDetails?.length > 0 ? true : false,
        // workDays: selectedClient?.availabilityDetails?.length > 0
        //     ? [] : [1, 2, 3, 4, 5]
      };
      newList.push(obj);
    });

    return newList;
  };

  var islayoutChanged = true;

  const onDataBinding = useCallback(() => {
    if (islayoutChanged && scheduleObj && scheduleObj.current) {
      //1. get rendered dates in current view
      var renderedDates = scheduleObj.current.activeView.getRenderDates();

      //2. ensure to reset all working hours (Must Have!!)
      scheduleObj.current.resetWorkHours(
        [],
        "06:00", //same as calendar default start time
        "21:00" //same as calendar default end time;
      );

      //3. get working hours for each resource
      let workingHoursMaps: any[][] = [
        ...workHoursDynamic(renderedDates[0], childDetailList),
      ];

      for (var i = 0; i < renderedDates?.length; i++) {
        try {
          var dayIndex = renderedDates[i].getDay();

          //4. set working hours for each resource
          for (let j = 0; j < workingHoursMaps.length; j++) {
            if (scheduleObj.current && workingHoursMaps[j][dayIndex]) {
              if (
                workingHoursMaps[j][dayIndex].StartTime === "00:00" &&
                workingHoursMaps[j][dayIndex].EndTime === "00:00"
              ) {
                scheduleObj.current.setWorkHours(
                  [renderedDates[i]],
                  "24:00",
                  "24:00",
                  j //groupIndex = resourceIndex
                );
              } else {
                scheduleObj.current.setWorkHours(
                  [renderedDates[i]],
                  workingHoursMaps[j][dayIndex].StartTime,
                  workingHoursMaps[j][dayIndex].EndTime,
                  j
                );
              }
            } else {
              scheduleObj.current.setWorkHours(
                [renderedDates[i]],
                "24:00",
                "24:00",
                j //groupIndex = resourceIndex
              );
            }
          }
          scheduleObj.current.workHours.highlight = false;
        } catch (e: any) {
          console.log("e:", e);
        }
      }
    }
  }, [islayoutChanged, childDetailList]);

  //render the cells in Scheduler
  function handleRenderCell(args: RenderCellEventArgs): void {
    if (args.element.classList.contains("e-work-cells")) {
    }
    if (
      args.elementType === "emptyCells" &&
      args.element.classList.contains("e-resource-left-td")
    ) {
      let target: HTMLElement = args.element.querySelector(
        ".e-resource-text"
      ) as HTMLElement;
      target.innerHTML =
        '<div class="name">Person</div><div class="type">List</div>';
    }
  }

  //render the evenets in Scheduler
  function onEventRendered(args: EventRenderedArgs): void {
    if (args.data.ServiceType) {
      let color = "";
      let border = "";
      let s = args.data.ServiceType;
      if (s === ServiceColor.Assessment) {
        if (args.data.status === "Pending") {
          color = "#E6CCFF";
          border = "2px dotted red";
        } else {
          color = "#E6CCFF";
        }
      } else if (s === ServiceColor.DirectTherapy) {
        if (args.data.status === "Pending") {
          color = "#E1F8DC";
          border = "2px dotted red";
        } else {
          color = "#E1F8DC";
        }
      } else if (s === ServiceColor.ParentTraining) {
        if (args.data.status === "Pending") {
          color = "#F2D1CB";
          border = "2px dotted red";
        } else {
          color = "#F2D1CB";
        }
      } else if (s === ServiceColor.ProtocolModification) {
        if (args.data.status === "Pending") {
          color = "#b7e2fc";
          border = "2px dotted red";
        } else {
          color = "#b7e2fc";
        }
      }
      args.element.style.backgroundColor = color;
      args.element.style.color = "#000";
      args.element.style.border = border;

      if (args.data.status === "Cancelled") {
        args.element.style.textDecorationLine = "line-through";
      }
    }
  }

  //recreate work hours dynamically
  const workHoursDynamic = (
    d1: any,
    selectedClients: any,
    fromMatchResults: boolean = false
  ) => {
    let results: any[] = [];
    let uniqueIds: string[] = [];
    selectedClients.forEach((item: any) => {
      if (uniqueIds.indexOf(item.clientBasicDetails.id) === -1) {
        uniqueIds.push(item.clientBasicDetails.id);
        results.push(
          availabilityOverlap(
            moment(d1).format("MM/DD/YYYY"),
            item?.availabilityDetails
          )
        );
      }
    });

    return results;
  };

  const filteredClientSchedules = useSelector((state: RootState) => {
    const today = new Date();
    return filterSchedulesByIds(
      clientbooking,
      childIds, //client Id List
      [], //providerId List
      new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()), //[-1 year, + 1 year]
      true //includeCancel
    );
  });

  //handles Client Booked Data Population
  const populateClientBookedData = useCallback(() => {
    let clientResults: any[] = [];
    let bookSchedule: any[] = [];

    bookSchedule = [...filteredClientSchedules];

    populateBookedData("CLIENT", bookSchedule, clientResults);

    return clientResults;

    //setClientBooked(clientResults);
  }, [filteredClientSchedules]);

  //handle booked data
  function populateBookedData(
    clientOrProvider: string,
    bookedSlots: any[],
    results: any[]
  ) {
    let specificDate = new Date();
    let fromTimeStr = "";
    let toTimeStr = "";
    let fromDateTime = new Date();
    let toDateTime = new Date();

    for (let i = 0; i < bookedSlots?.length; i++) {
      specificDate = new Date(
        moment.utc(bookedSlots[i]?.specificDate).format("MM/DD/YYYY")
      );
      fromTimeStr = convertTime12to24(bookedSlots[i]?.fromTime);
      toTimeStr = convertTime12to24(bookedSlots[i]?.toTime);
      const [fromHour, fromMin, fromSec] = fromTimeStr.split(":");
      const [toHour, toMin, toSec] = toTimeStr.split(":");

      fromDateTime = new Date(
        specificDate.getFullYear(),
        specificDate.getMonth(),
        specificDate.getDate(),
        +fromHour,
        +fromMin,
        +fromSec
      );

      toDateTime = new Date(
        specificDate.getFullYear(),
        specificDate.getMonth(),
        specificDate.getDate(),
        +toHour,
        +toMin,
        +toSec
      );

      var bookedBlock = {
        Subject: bookedSlots[i].bookedClientId
          ? `${bookedSlots[i].bookedClientId.childFirstName}${" "}${
              bookedSlots[i].bookedClientId.childLastName
            }${" - "}${bookedSlots[i].bookedProviderId.firstName}${" "}${
              bookedSlots[i].bookedProviderId.lastName
            }`
          : `${bookedSlots[i].bookedProviderId.firstName}${" "}${
              bookedSlots[i].bookedProviderId.lastName
            }`,
        StartTime: fromDateTime.toISOString(),
        EndTime: toDateTime.toISOString(),

        bookedClientId: bookedSlots[i].bookedClientId,
        bookedProviderId: bookedSlots[i].bookedProviderId,

        repeatWorkDays: bookedSlots[i].repeatWorkDays,
        repeatCustomEnd: bookedSlots[i].repeatCustomEnd,
        serviceID: bookedSlots[i].id,
        specificDate: bookedSlots[i].specificDate,
        whoCancelled: bookedSlots[i].whoCancelled,
        cancellationReason: bookedSlots[i].cancellationReason,
        authId: bookedSlots[i].authId,
        authCode: bookedSlots[i].authCode,

        clientId:
          clientOrProvider === "CLIENT"
            ? bookedSlots[i].bookedClientId?.id
            : "",

        Repeat: bookedSlots[i].repeatService,
        ServiceType: bookedSlots[i].serviceType,
        Location: bookedSlots[i].serviceLocation,
        Description: bookedSlots[i].description,
        status: bookedSlots[i].status,

        isAllDay: false,
        IsBlock: false, //clientOrProvider === "CLIENT" ? true : false,
        id:
          clientOrProvider === "CLIENT"
            ? bookedSlots[i].bookedClientId?.id
            : bookedSlots[i].bookedProviderId?.id,
        GroupId: clientOrProvider === "CLIENT" ? 3 : 4, //booked slots
        bookingId: bookedSlots[i].id,
        createdAt: bookedSlots[i].createdAt,
        updatedAt: bookedSlots[i].updatedAt,
        category: bookedSlots[i].category,
      };

      results.push(bookedBlock);
    }
  }

  //handles popup open
  const onPopupOpen = (e: PopupOpenEventArgs) => {
    let data: Record<string, any> = e.data as Record<string, any>;
    setargsData(data);

    //stores created time
    const givenTime = moment(data?.StartTime);

    // stores current time
    const currentTime = moment();

    // Calculate the time difference between current time and created time
    const timeDifference = givenTime.diff(currentTime, "minutes");

    // Access the popup footer element
    const popupFooterElement = e.element.querySelector(".e-popup-footer");

    // Modified the titles of the default buttons
    if (popupFooterElement) {
      const editButton = popupFooterElement.querySelector(
        ".e-event-edit"
      ) as HTMLButtonElement;
      const deleteButton = popupFooterElement.querySelector(
        ".e-event-delete"
      ) as HTMLButtonElement;
      const saveButton = popupFooterElement?.querySelector(
        ".e-event-create"
      ) as HTMLButtonElement;

      //hide save button on clicking blank cell
      if (saveButton) {
        saveButton.style.display = "none";
      }

      if (scheduleSetting.allowParentToCancel && data?.Id) {
        if (editButton) {
          if (e.data?.status === "Cancelled") {
            editButton.style.display = "none";
            if (scheduleSetting.allowParentToRebook) {
              //allow parent to rebook
              deleteButton.title = t(`main.schedule.re_schedule`);
              deleteButton.innerText = t(`main.schedule.re_schedule`);
              setScheduleAction(ParentScheduleAction.ReSchedule);
              console.log("created->reschedule")
            } else {
              if (deleteButton) {
                deleteButton.style.display = "none";
              }
              const contactUsText = document.createElement("div");
              contactUsText.innerText = t(
                "main.schedule.rebooking_not_allowed"
              );
              contactUsText.style.color = "red";
              contactUsText.style.display = "flex";
              contactUsText.style.fontSize = "16px";
              contactUsText.style.padding = "0px 5px 10px 0px";
              popupFooterElement?.appendChild(contactUsText);
              console.log("rebooking_not_allowed");
            }
          }
        }

        //b. show cancel-booking
        if (deleteButton) {
          // if (e.data?.status === "Edited" || e.data?.status === "Created") {
          let message: string = "";
          let allowed: boolean = true;
          if (timeDifference < 0) {
            message = t("main.schedule.past_event");
            allowed = false;
          } else if (timeDifference <= scheduleSetting.minHrsToCancel * 60) {
            message = ` ${
              t("main.schedule.cannot_cancel") +
              scheduleSetting.minHrsToCancel +
              t("main.schedule.hours_limit")
            }`;
            allowed = false;
          } else if (!scheduleSetting.serviceType.includes(data?.ServiceType)) {
            message = t("main.schedule.service_limit");
            allowed = false;
          } else if (data?.status === "Cancelled") {
            message = t("main.schedule.already_cancelled");
            allowed = false;
          }
          console.log("allowed, message: ", allowed, message, e.data?.status);
          //note: defer to check maxBookingToCancel rule at the runtime.

          if (allowed) {
            editButton.style.display = "none";
            if (scheduleSetting.allowParentToRebook) {
              deleteButton.title = t(`main.schedule.re_schedule`);
              deleteButton.innerText = t(`main.schedule.re_schedule`);
              setScheduleAction(ParentScheduleAction.ReSchedule);
              console.log("re-schedule");
            } else {
              deleteButton.title = t(`main.schedule.cancel_booking`);
              deleteButton.innerText = t(`main.schedule.cancel_booking`);
              setScheduleAction(ParentScheduleAction.Cancel);
              console.log("cancel booking");
            }
            // Set styles for "Cancel Booking" button
            deleteButton.style.color = "white";
            deleteButton.style.backgroundColor = "#0d6efd";
          } else {
            //not allowed!!
            console.log("not allowed");
            if (editButton) {
              editButton.style.display = "none";
            }

            if (deleteButton) {
              deleteButton.style.display = "none";
            }
            // Show "Contact us for Re-booking" text
            const contactUsText = document.createElement("div");
            contactUsText.innerText = message;
            contactUsText.style.color = "red";
            contactUsText.style.display = "flex";
            contactUsText.style.fontSize = "16px";
            contactUsText.style.padding = "0px 5px 10px 0px";
            popupFooterElement?.appendChild(contactUsText);
          }
          // }
        }
      } else {
        //when allowParentToCancel is false;
        console.log("allowParentToCancel is false");
        if (editButton) {
          editButton.style.display = "none";
        }

        if (deleteButton) {
          deleteButton.style.display = "none";
        }

        // Show "Contact us for Cancellation or Re-booking" text
        const contactUsText = document.createElement("div");
        contactUsText.innerText = data?.Id
          ? t("main.schedule.contact_cancel_re-book")
          : "";
        contactUsText.style.color = "red";
        contactUsText.style.display = "flex";
        contactUsText.style.fontSize = "16px";
        contactUsText.style.padding = "0 5px 10px 0px";
        popupFooterElement?.appendChild(contactUsText);
      }
    }

    // console.log("scheduleAction:", scheduleAction);

    try {
      if (
        scheduleAction === ParentScheduleAction.Cancel &&
        e.type === "DeleteAlert"
        // (e.type === "Editor" && e.data?.status !== "Cancelled")
      ) {
        if (scheduleSetting.allowParentToCancel) {
          setIsDeletePopup(true);
        }
        e.cancel = true;
      }

      //must have! do not show-popup for open area!
      if (!e.data?.Id) {
        e.cancel = true;
        return;
      }
      if (e.type === "Editor" || e.type === "DeleteAlert") {
        e.cancel = true;
        if (
          scheduleSetting.allowParentToRebook
          // e.data?.status === "Cancelled"
          // isRebook
        ) {
          setPopup(true);
          setIsDeletePopup(false);
        }

        // Create required custom elements in initial time
        if (!e.element.querySelector(".custom-field-row")) {
          let row: HTMLElement = createElement("div", {
            className: "custom-field-row",
          });
          let formElement: any = e.element.querySelector(".e-schedule-form");
          formElement?.firstChild.insertBefore(
            row,
            e.element.querySelector(".e-title-location-row")
          );
          let container: HTMLElement = createElement("div", {
            className: "custom-field-container",
          });
          let inputEle: HTMLInputElement = createElement("input", {
            className: "e-field",
            attrs: { name: "ServiceType" },
          }) as HTMLInputElement;
          container.appendChild(inputEle);
          row.appendChild(container);
          let dropDownList: DropDownList = new DropDownList({
            dataSource: [
              { text: "Assessment", value: "assessment" },
              { text: "Direct Therapy", value: "direct-therapy" },
              { text: "Parent Training", value: "parent-training" },
              { text: "Protocol modification", value: "protocol-modification" },
            ],
            fields: { text: "text", value: "value" },
            floatLabelType: "Always",
            placeholder: "Service Type",
          });
          dropDownList.appendTo(inputEle);
          inputEle.setAttribute("name", "ServiceType");
        }
      }
    } catch (e) {
      console.log("exception:", e);
    }
  };

  //#endregion

  //#region useEffect region

  useEffect(() => {
    if (childDetailList !== undefined) {
      console.log("repopulate calendar data 111");
      const listData = handlePopulateResources(childDetailList);
      setlistforCalendar(listData);
    }
    // eslint-disable-next-line
  }, [childDetailList]);

  useEffect(() => {
    console.log("repopulate calendar data 222", listforCalendar?.length);
    const clientResults = populateClientBookedData();
    setEventData([...clientResults]);
    // eslint-disable-next-line
  }, [listforCalendar, childDetailList]);

  //#endregion
  // console.log("popup, isDeletePopup;", popup, isDeletePopup);

  const Scheduler = useMemo(() => {
    return (
      <ScheduleComponent
        ref={scheduleObj}
        width="100%"
        height="750px"
        rowAutoHeight={true}
        dateHeaderTemplate={dateHeaderTemplate}
        currentView="Month"
        resourceHeaderTemplate={resourceHeaderTemplate}
        renderCell={handleRenderCell.bind(this)}
        dataBinding={onDataBinding}
        eventRendered={onEventRendered.bind(this)}
        popupOpen={(e) => {
          onPopupOpen(e);
        }}
        cellDoubleClick={(e) => {
          e.cancel = true;
        }}
        eventSettings={{
          dataSource: [...eventData],

          fields: {
            id: "Id",
            subject: { title: "Summary", name: "Subject" },
            isAllDay: { name: "IsAllDay" },
            location: { title: "Location", name: "Location" },
            endTimezone: { name: "TimeZone" },
            startTimezone: { name: "TimeZone" },
            description: { title: "Description", name: "Description" },
            startTime: { title: "From", name: "StartTime" },
            endTime: { title: "To", name: "EndTime" },

            // recurrenceRule: { name: "Recurences" },
            // recurrenceException: { name: "RecurencesException" },
          },
        }}
        group={{
          resources: ["CalendarList"],
          // enableCompactView: false,
        }}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="id"
            title="Provider"
            name="CalendarList"
            allowMultiple={true}
            dataSource={listforCalendar}
            textField="name"
            idField="id"
            groupIDField="GroupId"
            colorField="color"
            workDaysField="workDays"
            startHourField="startHour"
            endHourField="endHour"
          ></ResourceDirective>
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective
            option="TimelineDay"
            startHour="06:00"
            endHour="21:00"
          />
          <ViewDirective option="Day" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Week" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Month" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Year" startHour="06:00" endHour="21:00" />
          <ViewDirective option="Agenda" startHour="06:00" endHour="21:00" />
        </ViewsDirective>
        <Inject
          services={[
            Day,
            Week,
            Month,
            Year,
            TimelineViews, //day & week
            // TimelineMonth,
            Resize,
            DragAndDrop,
            Agenda,
          ]}
        />
      </ScheduleComponent>
    );
    // eslint-disable-next-line
  }, [eventData, listforCalendar?.length]);

  return (
    <div>
      <div className="control-section">{Scheduler}</div>
      {popup && (
        <>
          <DiscoverBookingEditModal
            pageTitle={scheduleAction}
            isDelete={false}
            isEdit={true}
            closePopup={setPopup}
            argsdata={argsdata}
            fromPlanning={true}
            clientBooking={clientbooking}
            childIds={childIds}
          />
        </>
      )}
      {isDeletePopup && (
        <>
          <DiscoverBookingEditModal
            pageTitle={scheduleAction}
            isDelete={true}
            isEdit={false}
            closePopup={setIsDeletePopup}
            argsdata={argsdata}
            fromPlanning={true}
            clientBooking={clientbooking}
            childIds={childIds}
          />
        </>
      )}
    </div>
  );
};

export default ScheduleCalendar;
