import axios from "../axios/axios";

import { Response } from "../type/response";

//Get Parent Assessment List list
export const getAssessmentlist = async (id: string) => {
  try {
    const { data } = await axios.get(`/parent/assessment/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//Get Parent Assessment
export const getAssessmentById = async (id: string) => {
  try {
    const { data } = await axios.get(`/formrequest/${id}`);
  
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update assessment result
export const updateFormResult = async (id: string, res: any) => {
  try {
    const { data } = await axios.put(`/formrequest/${id}`, res);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const updateSessionFormRequest = async (
  id: string,
  payload: {
    formData: any;
    submitters: {id:string,role:string,isSubmitted:boolean}[];
  }
) => {
  try {
    const { data } = await axios.put(
      `/formrequest/${id}/updateSessionFormRequest`,
      payload
    );
    return data;
  } catch (error: any) {
    throw error.response?.data;
  }
};

const formService = {
  getAssessmentlist,
  getAssessmentById,
  updateFormResult,
  updateSessionFormRequest
};

export default formService;