import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Model, settings } from "survey-core";
import { Survey } from "survey-react-ui";

import "survey-core/defaultV2.min.css";
import "./SurveyComponent.css";

import { themeJson } from "./theme";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { IResultfinal } from "../../type/form";
import SurveyComplete from "./SurveyComplete";
import "survey-core/survey.i18n";
import "survey-core/i18n/spanish";
import { useTranslation } from "react-i18next";
import { Serializer } from "survey-core";
import {
  setLoadingStatus,
  UpdateFormResult,
  UpdateSessionFormRequest,
} from "../../redux/features/form/form-slice";

interface ISurveyform {
  // setFormStart: React.Dispatch<React.SetStateAction<boolean>>;
  count: number;
}

const SurveyComponent: React.FC<ISurveyform> = ({ count }) => {
  //dispatch
  const dispatch = useAppDispatch();

  //translation
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  //used for redirecting to completeSurvey Page
  const [_, setSaved] = useState<boolean>(false);

  //used to handle assessment form
  const formRequest = useAppSelector(
    (state) => state.assessmentformSlice.assessmentform
  );

  // store Child Details
  const currentChild = useAppSelector(
    (state) => state.clientSlice.currentChild
  );

  const loadingStatus = useAppSelector(
    (state) => state.assessmentformSlice.loadingStatus
  );
  // A variable that will store files until the survey is completed
  const [fileStorage, setFileStorage] = useState<any>({}); //questionName -> files

  //add these to ensure survey.onUploadFiles will be called.
  //This is needed to store the files in the temporary storage
  Serializer.getProperty("file", "allowMultiple").defaultValue = true;
  Serializer.getProperty("file", "waitForUpload").defaultValue = true;
  Serializer.getProperty("file", "needConfirmRemoveFile").defaultValue = true;
  Serializer.getProperty("file", "storeDataAsText").defaultValue = false;

  const [survey, setSurvey] = useState<Model>(
    new Model(formRequest?.form?.formSchema)
  );
  //Set this property to false to execute the skip trigger on next page instead of on value change
  settings.triggers.executeSkipOnValueChanged = false;

  const [isRunning, setIsRunning] = React.useState(true);

  // used for navigation
  const navigate = useNavigate();
  // You can delete the line below if you do not use a customized theme
  survey.applyTheme(themeJson);

  //show Progress bar
  // survey.showProgressBar = "Top";

  survey.onUploadFiles.add((sender: any, options: any) => {
    // console.log("calling onUploadFiles", fileStorage, options);
    // Add files to the temporary storage
    let tempFileStorage = fileStorage;

    //lookup by question name
    if (tempFileStorage && tempFileStorage[options.name] !== undefined) {
      tempFileStorage[options.name] = [
        ...tempFileStorage[options.name],
        ...options.files,
      ];
    } else {
      tempFileStorage[options.name] = options.files;
    }
    setFileStorage(tempFileStorage);
    // console.log("fileStorage after set:", fileStorage, tempFileStorage);

    // Load file previews
    const content: any[] = [];
    options.files.forEach((file: any) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        content.push({
          name: file.name,
          type: file.type,
          content: fileReader.result,
          file: file,
        });
        if (content.length === options.files.length) {
          // Return a file for preview as a { file, content } object
          options.callback(
            content.map((fileContent) => {
              return {
                file: fileContent.file,
                content: fileContent.content,
              };
            })
          );
        }
      };
      fileReader.readAsDataURL(file);
    });
  });
  //show Progress bar
  survey.showProgressBar = "Top";
  // // Handles file removal
  survey.onClearFiles.add((_, options) => {
    // Empty the temporary file storage if "Clear All" is clicked
    let tempFileStorage = fileStorage;

    if (options.fileName === null && tempFileStorage) {
      tempFileStorage[options.name] = [];
      options.callback("success");
      setFileStorage(tempFileStorage);
      return;
    }

    // Remove an individual file
    const tempFiles = tempFileStorage[options.name];
    if (!!tempFiles) {
      const fileInfoToRemove = tempFiles.filter(
        (file: any) => file.name === options.fileName
      )[0];
      if (fileInfoToRemove) {
        const index = tempFiles.indexOf(fileInfoToRemove);
        tempFiles.splice(index, 1);
      }
    }
    options.callback("success");
  });

  survey.onComplete.add((_, options) => {
    const resultData = [];
    saveSurveyData(survey, true);
    for (const key in survey.data) {
      const question = survey.getQuestionByName(key);
      if (!!question) {
        const item = {
          name: key,
          value: question.value,
          title: question.displayValue,
          displayValue: question.displayValue,
        };
        resultData.push(item);
      }
    }

    setSaved(true);
  });

  // Save survey results
  survey.onPartialSend.add((survey) => {
    saveSurveyData(survey, false);
  });

  // handle close assessment
  const closeAssessment = () => {
    navigate(-1);
  };

  //#region saveResult region

  // save survey Data
  const saveSurveyData = async (survey: any, isComplete: boolean) => {
    dispatch(setLoadingStatus("Loading"));
    const previewsData: any = formRequest.formData;
    const data = survey.data;

    // Create a new object with the first two key-value pairs removed
    var newObject: any = {};
    if (previewsData) {
      var keys = Object.keys(previewsData);
      for (var i = 2; i < keys.length; i++) {
        var key = keys[i];
        newObject[key] = previewsData[key];
      }
    }

    data.pageNo = survey.currentPageNo;
    const latestData = { ...newObject, ...data };
    // console.log("latestData:", previewsData, data, newObject, latestData);
    if (formRequest.form.formType === "Session Form") {
      const submitters = formRequest.submitters.map((item: any) => {
        if (item.id === currentChild.client.id) {
          return {
            ...item,
            isSubmitted: true,
          };
        }
        return item;
      });
      dispatch(
        UpdateSessionFormRequest({
          id: formRequest.id,
          formData: latestData,
          submitters,
        })
      );
    } else {
      dispatch(
        UpdateFormResult({
          id: formRequest.id,
          result: latestData,
          isComplete,
        })
      );
    }
  };

  //partial form
  if (formRequest.formData) {
    const data: IResultfinal = formRequest.formData;
    survey.data = formRequest.formData;
    if (data?.pageNo) {
      survey.currentPageNo = data?.pageNo;
    }
  }

  //#endregion

  // Define a function to remove styles from the "Next" button
  function removeStylesFromNextButton(sender: any, options: any) {
    // Find the "Next" button within the current question
    var nextButton = document.getElementById("sv-nav-next");
    // Check if the "Next" button exists
    if (nextButton) {
      // Remove the custom CSS class from the "Next" button
      nextButton.classList.remove("sv-nav-next");
    }
  }

  //handle percent progress result
  const progressResult = (item: number): number => {
    let val = 15;
    let multiplyer = 0;

    let result =
      item < val
        ? val * multiplyer
        : item <= val * (multiplyer + 2)
        ? val
        : item <= val * (multiplyer + 3)
        ? val * (multiplyer + 2)
        : item <= val * (multiplyer + 4)
        ? val * (multiplyer + 3)
        : item <= val * (multiplyer + 5)
        ? val * (multiplyer + 4)
        : item <= val * (multiplyer + 6)
        ? val * (multiplyer + 5)
        : item <= val * (multiplyer + 7) - 5
        ? val * (multiplyer + 6)
        : 100;
    return result;
  };

  survey.onStarted.add(() => {
    setIsRunning(true);
  });

  //changes language translation
  if (i18n.language === "es") {
    survey.locale = "es";
  } else if (i18n.language === "zh-CN") {
    survey.locale = "zh-cn";
  } else {
    survey.locale = "en";
  }

  const editableQuestionsSessionNote = ["parentName", "parentSignature"];

  survey.onAfterRenderQuestion.add((sender, options) => {
    if (
      formRequest.form.formType === "Session Form" &&
      !editableQuestionsSessionNote.includes(options.question.name)
    ) {
      options.question.readOnly = true;
    }
    // Find the "Next" button within the current question
    var nextButton = document.getElementById("sv-nav-next");
    if (
      survey.currentPage?.jsonObj?.name === "StartPage" ||
      survey.currentPage?.jsonObj?.name === "page1"
    ) {
      // Check if the "Next" button exists
      if (nextButton) {
        // Add custom CSS class to the "Next" button
        nextButton.classList.add("sv-nav-next");
      }
    }

    //progress bar
    var progressBar = document.getElementsByClassName("sd-progress__text");
    // Loop through the selected elements and change their content
    if (progressBar.length) {
      const percentageBar = `${
        (survey.currentPageNo / survey.PageCount) * 100
      }`;
      let result = parseInt(percentageBar);

      for (let i = 0; i < progressBar.length; i++) {
        progressBar[i].textContent = `${progressResult(result)}${" "}${"%"}`;
      }
    }
  });

  //#region page render region
  if (survey.currentPage?.jsonObj?.name === "page1") {
    survey.pageNextText = t("main.next");
  } else {
    survey.pageNextText = "Start Now";
  }

  survey.showPrevButton = false;
  let closeButton = document.getElementById("clsBtn") as HTMLElement;
  if (closeButton) {
    closeButton.onclick = () => {
      saveSurveyData(survey, false);
    };
  }

  let result: number = 0;
  let Counter: number = 0;
  let reserve: number = 0;
  let arrtest: number[] = [];

  survey.onFocusInQuestion.add(() => {
    //Added on closeButton
    let closeButton = document.getElementById("clsBtn") as HTMLElement;
    if (closeButton) {
      closeButton.onclick = () => {
        saveSurveyData(survey, false);
      };
    }
  });

  //handle per page render
  survey.onCurrentPageChanged.add((_, options) => {
    let accessValue: boolean = false;

    if (result < survey.currentPageNo) {
      result = survey.currentPageNo;
      arrtest.push(survey.currentPageNo);
      arrtest.sort((a, b) => a - b);
      Counter = Counter + 1;
      if (Counter === 1) reserve = survey.currentPageNo;
      accessValue = false;
    } else {
      result = survey.currentPageNo;
      arrtest.pop();
      accessValue = true;
      Counter = Counter - 1;
    }

    if (options.newCurrentPage.visibleIndex !== 0) {
      survey.onAfterRenderQuestion.add(removeStylesFromNextButton);
    }

    if (options.newCurrentPage.visibleIndex !== 0) {
      survey.pageNextText = t("main.next");
    } else {
      survey.pageNextText = "Start Now";
    }

    if (!accessValue) {
      if (survey.currentPageNo !== 0) {
        survey.showPrevButton = true;
        // if(arrtest.length === 2) survey.showPrevButton = false;
      } else {
        survey.showPrevButton = false;
      }
    } else {
      if (Counter !== 2) {
        survey.showPrevButton = true;
      } else {
        survey.showPrevButton = false;
      }
    }
  });
  //#endregion

  //#region closeButton region
  const renderButton = (
    text: string,
    func: any,
    canRender: boolean,
    ids: string
  ) => {
    if (!canRender) return undefined;

    return (
      <button className="navigation-button" id={ids} onClick={func}>
        {text}
      </button>
    );
  };

  const renderNavExternalNavigation = () => {
    if (!isRunning) return undefined;
    return (
      <div className="navigation-block topNavBlock">
        <div className="navigation-progress-container">
          <div className="navigation-buttons-container">
            {renderButton(t("main.save_form"), closeAssessment, true, "clsBtn")}
          </div>
        </div>
      </div>
    );
  };

  //#endregion

  //#region useEffect region
  useEffect(() => {
    if (!survey) {
      let updatedSurvey = new Model(formRequest.form.formSchema);
      updatedSurvey.data =
        formRequest.form.formType === "Session Form"
          ? formRequest.formData
          : {
              "nps-score": 9,
              "promoter-features": ["performance", "ui"],
            };
      setSurvey(new Model(formRequest.form.formSchema));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRequest]);

  //#endregion

  return (
    <>
      {loadingStatus !== "Pending" ? (
        <SurveyComplete />
      ) : (
        <>
          {renderNavExternalNavigation()}
          <Box bgcolor={"#f3f3f3"} className="surveyFormBlock">
            <Survey model={survey} />
          </Box>
        </>
      )}
    </>
  );
};

export default SurveyComponent;
