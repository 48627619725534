import { IAssessment, IAssessmentData, IAssessmentForm } from "../type/form";

export const Assessmentform: IAssessment = {
  id: "",
  form: {
    formCategory: "",
    createdAt: "",
    updatedAt: "",
  },
};

export const assessmentform: IAssessmentForm = {
  authCode: null,
  authId: null,
  cancelDtm: null,
  createdAt: "",
  createdBy: "",
  entityId: "",
  entityType: "",
  errorDtm: null,
  errorMsg: null,
  evaluateDtm: null,
  evaluation: null,
  form: {
    id: "",
    formCategory: "",
    formType: "",
    embeddedCode: null,
    formSchema: {
      title: "",
      logoPosition: "",
      showProgressBar: "",
      completedHtmlOnCondition: [{}],
      pages: [
        {
          name: "",
          elements: [],
        },
      ],
      triggers: [
        {
          type: "",
          expression: "",
          gotoName: "",
        },
      ],
      locale: ""
    },
    // authTenantId: string;
    descope_tenantId: "",
    // authSystem: string;
    // bussinessUnit: string;
    active: true,
    createdAt: "",
    updatedAt: "",
    isComplete: false,
    isJot: false,
    name: null,
    url: null,
  },
  formData: null,
  id: "",
  mapDtm: null,
  resultData: null,
  saveDtm: null,
  sentDtm: "",
  startDtm: null,
  status: "",
  submitDtm: null,
  updatedAt: "",
  submitters: []
};

// export const assessmentform:IAssessmentForm = {
//  id: "",
//   status: "",
//   result: null,
//   pdf: null,
//   form: {
//     id: "",
//     formCategory: "",
//     formSchema: {
//       title: "",
//       showProgressBar:"",
//       logoPosition: "",
//       completedHtmlOnCondition: [{}],
//       pages: [
//         {
//           name: "",
//           elements: [
//             // {
//             //   type: "",
//             //   name: "",
//             //   title: "",
//             // }
//           ],
//         }
//       ],
//       triggers: [
//         {
//           type: "",
//           expression: "",
//           gotoName: "",
//         }
//       ],
//     },
//     authTenantId: "",
//     authSystem: "",
//     bussinessUnit: "",
//     active: true,
//     createdAt: "",
//     updatedAt: "",
//   },
//   client: {
//     id: "",
//     descope_tenantId: "",
//     parentFirstName: "",
//     parentLastName: "",
//     childFirstName: "",
//     childLastName: "",
//     childDateOfBirth: null,
//     childGender: "",
//     childLanguages: [],
//     businessUnit: "",
//     clientType: "",
//     onTrackStage: "",
//     concernedStage: [],
//     intakeStage: "",
//     concerns: "",
//     hearAboutUs: "",
//     files: [],
//     clientProfile: {},
//     stage: "",
//     createdAt: "",
//     updatedAt: "",
//   },
//   completedDate: null,
//   createdAt: "",
//   updatedAt: "",
// };

export const AssessmentFormData: IAssessmentData = {
  assessmentList: [Assessmentform],
  assessmentform: assessmentform,
  loadingStatus: "Pending",
  errorMessage: ""
};
